<template>
	<div class="minstmarcajeFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.minstmarcaje.idinstmarca')" :value="getIdinstmarcaValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.minstmarcaje.codinsmarcaedi')" :value="getCodinsmarcaediValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.minstmarcaje.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'minstmarcaje-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'minstmarcaje'
		};
	},
	computed: {
		getIdinstmarcaValue() {
			return this.model && this.model.idinstmarca ? this.model.idinstmarca : '-';
		},
		getCodinsmarcaediValue() {
			return this.model && this.model.codinsmarcaedi ? this.model.codinsmarcaedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
